export const state = {
  id: null,
  name: null,
  email: null,
  photo: null,
}

export const getters = {
  isAuthenticated: state => state.id !== null,
}

export const mutations = {
  signIn (state, user) {
    state.id = user.uid
    state.name = user.displayName
    state.email = user.email
    state.photo = user.photoURL
  },
  signOut (state) {
    state.id = null
    state.name = null
    state.email = null
    state.photo = null
  }
}

export const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}