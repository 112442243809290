<template>
  <main>
    <div id="map" ref="map"></div>
    <ul id="filters">
      <li><label class="checkbox"><input type="checkbox" v-model="showUnplugged"><span> Unplugged</span></label></li>
      <li><label class="checkbox"><input type="checkbox" v-model="showCharging"><span> Charging</span></label></li>
      <li><label class="checkbox"><input type="checkbox" v-model="showDelayed"><span> Delayed</span></label></li>
    </ul>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      map: null,
      markers: [],

      showUnplugged: true,
      showCharging: true,
      showDelayed: true,
    }
  },
  watch: {
    showUnplugged () { return this.updateMarkerVisibility() },
    showCharging () { return this.updateMarkerVisibility() },
    showDelayed () { return this.updateMarkerVisibility() }
  },
  mounted () {
    const map = new google.maps.Map(this.$refs.map, {
      zoom: 2,
      center: new google.maps.LatLng(2.8, -187.3),
      mapTypeId: 'terrain'
    })

    this.markers = this.pins.map(pin => {
      const location = new google.maps.LatLng(pin.lat, pin.long)
      const marker = new google.maps.Marker({
          position: location,
          map: map,
          status: pin.status
        })
      const info = new google.maps.InfoWindow({
          content: pin.info
        })

      marker.addListener('click', function () {
        info.open(map, marker);
      })

      return marker
    })
  },
  methods: {
    updateMarkerVisibility () {
      this.markers.forEach(marker => {
        marker.setVisible(
          marker.status === 'unplugged' && this.showUnplugged ||
          marker.status === 'charging' && this.showCharging ||
          marker.status === 'delayed' && this.showDelayed
        )
      });
    }
  },
  computed: {
    ...mapGetters('Pins', ['pins'])
  }
}
</script>

<style lang="scss" scoped>
#map {
  height: 100%;
}
#filters {
  position: absolute;
  padding: 10px;
  left: 10px;
  bottom: 25px;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  background: #fff;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
</style>