import Vue from 'vue'

// 
// Initialize Firebase
// 

import * as firebase from 'firebase/app'
import { firebase as config } from '~/config'

firebase.initializeApp(config)


// 
// Initialize Firebase Auth
// 

import store from './store'
import 'firebase/auth'

// Hide app while authentication is happening
store.commit('setInitStatus', true)

firebase.auth().onIdTokenChanged(function(user) {
  if (!user) {
    store.dispatch('teardown')
    return
  }

  // Get auth token and sign the user in
  store.dispatch('setup', {
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL,
  })

  const pathOffset = window.location.hash.indexOf('?redirect=')

  if (pathOffset === -1) {
    router.push('/dashboard')
  }
  else {
    const redirectTo = decodeURIComponent(window.location.hash.substring(pathOffset + 10))
    router.push(redirectTo)
  }
})


// 
// Initialize Vue
// 

import Root from './components/Root.vue'
import router from './router'
import './filters'

new Vue({
  router,
  store,
  render: r => r(Root)
}).$mount('#app')