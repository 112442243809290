<template>
  <main>
    <div class="panel">
      <h1>Authentication required</h1>
      <form @submit.prevent="authenticate">
        <p v-if="$route.query.error === '403'" class="error">You&rsquo;ve been signed out, because these credentials do not have sufficient permissions.</p>
        <fieldset>
          <legend>Enter your credentials</legend>

          <div class="field">
            <label for="account-email">Email address</label>
            <input type="email" required id="account-email" v-model="email" />
          </div>

          <div class="field">
            <label for="account-password">Password</label>
            <input type="password" required id="account-password" v-model="password" />
          </div>

          <p v-if="!!error" class="error">{{error}}</p>
          <p class="buttons"><button class="action" type="submit" :disabled="disabled">Sign in</button></p>
        </fieldset>
      </form>

      <p class="alternatives">
        Alternatively, you can sign in with<br><br>
        <button @click="signInWith('google')" class="google">Google</button>
        or
        <button @click="signInWith('facebook')" class="facebook">Facebook</button>
      </p>
    </div>
  </main>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data () {
    return {
      disabled: false,
      error: '',
      email: '',
      password: '',
    }
  },
  methods: {
    authenticate () {
      this.disabled = true
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).catch((error) => {
        // this.error = `${error.code}: ${error.message}`
        this.error = `${error.message}`
        this.disabled = false
      })
    },
    signInWith (name) {
      let provider

      switch (name) {
        case 'google':
          provider = new firebase.auth.GoogleAuthProvider()
          break

        case 'facebook':
          provider = new firebase.auth.FacebookAuthProvider()
          break
      
        default:
          return
      }

      firebase.auth().signInWithRedirect(provider)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

main {
  display: flex;
  box-sizing: border-box;
  // height: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  > div {
    width: 540px;
  }
}

form > .error {
  margin-top: 10px;
}
fieldset {
  background: $default-background;
  
  p:last-child {
    margin-bottom: 16px;
  }
}

.alternatives {
  button {
    position: relative;
    padding-left: 40px;
    border-radius: 2px;
    font-weight: 700;

    @include icon-left (1, 2);

    &:disabled {
      opacity: 0.75;
      cursor: default;

      &:hover, &:focus {
        border-color: $border-color;
      }
    }

    &::before {
      left: 5px;
    }

    &.facebook {
      @include icon-offset (0, 2);
      background-color: #3b5998;
      color: #fff;
    }
  }
}

h1, .alternatives, .buttons, legend {
  text-align: center;
}
</style>