import { getMapPins, getAccount } from '~/api'

export const state = {
  initializing: false,
  loading: false,
  fleetId: null,
}

export const getters = {
  isInitialized: state => !state.initializing,
  isLoading: state => state.loading,
  fleetId: state => state.fleetId,
}

export const mutations = {
  setInitStatus (state, value) {
    state.initializing = !!value
  },
  setLoadingStatus (state, value) {
    state.loading = !!value
  },
  setFleetId (state, fleetId) {
    state.fleetId = fleetId
  },
}

export const actions = {
  setup ({ commit }, account) {
    commit('Account/signIn', {
      uid: account.uid,
      displayName: account.displayName,
      email: account.email,
      photoURL: account.photoURL,
    })

    getAccount().then(function (account) {
      commit('setFleetId', account.fleetId)
      
      getMapPins(account.fleetId).then(function (pins) {
        commit('Pins/setPins', pins)
      }).catch(function (error) {
        console.error(error)
      }).finally(function () {
        commit('setInitStatus', false)
      })
    }).catch(function (error) {
      console.error(error)
    })
  },
  teardown ({ commit }) {
    commit('Account/signOut')

    commit('setInitStatus', false)
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}