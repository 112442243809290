import * as firebase from 'firebase/app'
import 'firebase/auth'

import axios from 'axios'
import { api as config } from '~/config'

//
// Setup API + Firebase token authentication
//

const api = axios.create(config)

let AUTH_TOKEN = ''
let AUTH_LAST = 0

const TOKEN_TTL = 60 * 1000 // 60 seconds

function refreshAuthToken () {
  if (AUTH_LAST + TOKEN_TTL > Date.now()) {
    return new Promise(resolve => resolve(AUTH_TOKEN))
  }
  else return firebase.auth().currentUser.getIdToken().then(function (token) {
    AUTH_TOKEN = token
    AUTH_LAST = Date.now()

    // console.log('AUTH_TOKEN', AUTH_TOKEN)
  })
}

api.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Firebase ${AUTH_TOKEN}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

function promiseToGet (endpoint, success, failure) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api.get(endpoint).then(function ({ data }) {
        success(data, resolve)
      }).catch(failure === undefined ? reject : failure)
    })
  })
}

// 
// API endpoints
// 

export function getAccount () {
  return promiseToGet('/users/me/fleet', function (data, resolve) {
    resolve({
      name: stringValue(data.firstName) + ' ' + stringValue(data.lastName),
      photo: data.imageUrl,
      fleetId: data.fleetId,
      currency: data.currency
    })
  }, function (error) {
    if (error.response.status === 403) {
      firebase.auth().signOut()
      window.location.hash = '#/authentication?error=403'
    }
  })
}

export function getMapPins (fleetId) {
  // return new Promise(function (resolve, reject) {
  //   resolve([
  //     {
  //       lat: 2.8,
  //       long: -187.3,
  //       info: 'Bunny is a rabbit',
  //       status: 'unplugged'
  //     },
  //     {
  //       lat: 2.2,
  //       long: -13.3,
  //       info: 'Kitty is a cat',
  //       status: 'charging'
  //     },
  //     {
  //       lat: 45,
  //       long: 52.3,
  //       info: 'Puppy is a dog',
  //       status: 'delayed'
  //     }
  //   ])
  // })
  return promiseToGet(`/fleet/${fleetId}/map`, function (data, resolve) {
    resolve(data.chargers)
  })
}

//
// Utilities
//

function nullOrRatio (value) {
  return isNaN(value) || value == null ? null : value / 100
}

function stringValue (value) {
  return value == null? '' : value
}

function numericalValue (value) {
  return isNaN(value) ? 0 : value
}