import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Global from './Global'
import Pins from './Pins'
import Account from './Account'

const store = new Vuex.Store({
  modules: {
    Global,
    Pins,
    Account,
  },
  strict: process.env.NODE_ENV !== 'production',
  // strict: false
})

export default store