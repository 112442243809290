export const state = {
  pins: []
}

export const getters = {
  pins: state => state.pins
}

export const mutations = {
  setPins (state, pins) {
    state.pins = pins
  }
}

export const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}