export const api = {
  baseURL: 'https://api.ohme.io/v1/',
}

export const firebase = {
  apiKey: 'AIzaSyCeBO_09CK-8EAFBe8N5jA_hVP-Ik-YLPA',
  authDomain: 'ohme-prod.firebaseapp.com',
  databaseURL: 'https://ohme-prod.firebaseio.com',
  projectId: 'ohme-prod',
  storageBucket: 'ohme-prod.appspot.com',
  messagingSenderId: '206163667850',
  appId: '1:206163667850:web:05a242df77b45f59503673',
  measurementId: 'G-WSY0S31MJ7'
}