import * as production from '~/config.prod.js'
import * as development from '~/config.dev.js'

let isProduction = true

switch (window.location.hostname) {
  case 'localhost':
  case 'apps-dev.ohme-ev.com':
    isProduction = false
}

export const api = isProduction ? production.api : development.api
export const firebase = isProduction ? production.firebase : development.firebase