<template>
  <div v-if="isInitialized" id="app" :class="{ 'has-menu': hasMenu }">
    <Menu v-if="hasMenu" id="menu" />
    <router-view id="view" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Menu from 'elements/Menu.vue'

export default {
  name: 'Ohme-fleet-management',
  components: { Menu },
  mounted () {
    function handleFirstTab (e) {
      if (e.key === 'Tab' || e.keyCode === 9) {
        document.body.classList.add('outline-focused-elements')
        window.removeEventListener('keydown', handleFirstTab)
      }
    }

    window.addEventListener('keydown', handleFirstTab)
  },
  computed: {
    hasMenu () {
      return this.$route.path !== '/authentication'
    },
    ...mapGetters(['isInitialized'])
  }
}
</script>

<style lang="scss">
@import 'normalize.css';
@import '~/common.scss';
@import 'src/fonts/stylesheet';

html, body, #view {
  height: 100%;
}

// 
// Layout
// 

#menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: $menubar-height;
}

#app.has-menu {
  box-sizing: border-box;
  padding-top: ($menubar-height + $body-padding);
  height: 100%
}


// 
// Utility classes
// 

.invisible {
  overflow: hidden;
  text-indent: -999em;
  text-align: left;
}
span.invisible {
  position: absolute;
  left: -999em;
  text-align: left;
}


// 
// Typography
// 

html {
  background: $alt-background;
  color: $default-text;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: $bold-text;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
}

h2, h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

ul, ol, dl, p {
  margin: 0;
}

ul, ol {
  list-style: none;
  padding-left: 0;
}

.unit {
  font-size: .6875em;
}

abbr[title] {
  text-decoration: none;
}


// 
// Inputs
// 

a {
  text-decoration: none;
  color: $primary-color;
}

button, select, textarea, input[type="search"], input[type="text"], input[type="password"], input[type="email"], input[type="date"] {
  -webkit-appearance: none;
  display: inline-block;
  box-sizing: border-box;
	margin: 0;
  padding: 11px 15px;
  border: 1px solid $border-color;
  border-radius: 0;
  background-color: #fff;
  color: $default-text;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.5;

  // FIX iOS zooming in when font size is below 16px
  padding: 8px 15px;
  font-size: 1rem;

  &:focus, &:hover {
    border-color: $outline-color;

    &:disabled {
      border-color: $border-color;
    }
  }

  &:disabled {
    // cursor: default;
    color: $inactive-text;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 25px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAAXUlEQVR42p2PsQ2AQBDDsgQr8TAAS9IcNAz1S4CUAqFvrGDpypxsqR275rr/nLdar0mtej6u7q1Zzi194M0Hp0TqA29Koj7ilFDdQAqoQwqqQwqoYwqrcwqocwqpPyANy1lOFaotAAAAAElFTkSuQmCC),
    linear-gradient(to bottom, #fff 0%,#fff 100%);
  background-repeat: no-repeat, repeat;
  background-size: 8px 6px, 100%;
  background-position: right 15px top 50%, 0 0;

  option {
    font-size: normal;
  }

  &::-ms-expand {
    display: none;
  }

  &.empty {
    color: $placeholder-color;
  }
}

:focus {
  outline-color: $outline-color;
}

::placeholder {
  color: $placeholder-color;
}

body:not(.outline-focused-elements) {
  button, input, select, textarea {
    &:focus {
      outline: none;
    }
  }
}

button {
  cursor: pointer;
  white-space: nowrap;

  &.action, &.confirm, &.cancel {
    display: inline-block;
    min-width: 208px;
    padding: 13px 18px;
    border: 2px solid $primary-color;
    border-radius: 4px;
    background: $primary-color;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
    text-transform: uppercase;

    &:disabled {
      cursor: default;
      background: $inactive-color;
      border-color: $inactive-color;
    }
  }

  &.cancel {
    background: transparent;
    color: $primary-color;

    &:disabled {
      background: transparent;
      border-color: $inactive-color;
      color: $inactive-color;
    }
  }
}

form {
  .error {
    margin-bottom: 25px;
    color: $error-color;
  }
}

fieldset, section.fieldset {
  position: relative;
  padding: 72px 20px 14px;
  margin: 16px 0 30px;
  border: 2px solid #E5ECF7;
  border-radius: 4px;

  legend, h2.legend, h3.legend, h4.legend {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 27px;
    color: $bold-text;
    font-size: 1.375rem;
    font-weight: 600;
  }

  .field {
    margin-bottom: 16px;

    label[for] {
      display: block;
      margin: 10px 0;
      color: $label-text;
      font-size: .875rem;
      font-weight: 600;
      text-transform: uppercase;

      .hint {
        color: $deemphasized-text;
        font-size: .75rem;
        font-weight: 400;
        line-height: 1.125;
        text-transform: none;
        margin-left: 0.5em;
      }
    }

    .has-icon {
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 21px;
        margin: -12px 0 0 -12px;
        width: 24px;
        height: auto;
        pointer-events: none;
      }

      select, textarea, input {
        padding-left: 38px;
      }
    }

    select, textarea, input[type="text"], input[type="password"], input[type="email"], input[type="date"] {
      box-sizing: border-box;
      width: 100%;
    }
  }
}

label.radio, label.checkbox {
  overflow: hidden;

  input {
    position: absolute;
    left: -999em;
  }

  span {
    position: relative;
    display: inline-block;
    padding-left: 22px;
    
    @include icon-left (3, 0);
    &::before { left: -8px }
  }

  &:hover span, input:focus + span { @include icon-offset (3, 1) }
  input:checked + span { @include icon-offset (3, 2) }
}

label.checkbox {
  span { @include icon-offset (4, 0) }
  &:hover span, input:focus + span { @include icon-offset (4, 1) }
  input:checked + span { @include icon-offset (4, 2) }
}

</style>